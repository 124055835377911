@font-face {
    font-family: 'Satoshi-Regular';
    src: url('../fonts/satoshi/Satoshi-Regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/satoshi/Satoshi-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/satoshi/Satoshi-Regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/satoshi/Satoshi-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Satoshi-Bold';
    src: url('../fonts/satoshi/Satoshi-Bold.eot'); /* IE9 Compat Modes */
    src: url('../fonts/satoshi/Satoshi-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/satoshi/Satoshi-Bold.woff') format('woff'), /* Modern Browsers */
    url('../fonts/satoshi/Satoshi-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Satoshi-Black';
    src: url('../fonts/satoshi/Satoshi-Black.eot'); /* IE9 Compat Modes */
    src: url('../fonts/satoshi/Satoshi-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/satoshi/Satoshi-Black.woff') format('woff'), /* Modern Browsers */
    url('../fonts/satoshi/Satoshi-Black.ttf') format('truetype'), /* Safari, Android, iOS */
}

$font-main: 'Satoshi-Regular';

$font-bold: 'Satoshi-Bold';

$font-black: 'Satoshi-Black';


