@use "../../../assets/scss/colors";
@use "../../../assets/scss/typography";

.login-container {
    margin: 0px auto;
    padding: 30px;
    max-width: 400px;
    background-color: colors.$primary-green;
    font-family: typography.$font-main;
    color: #FFF;
    border-radius: 20px;
}

.gray-bg {
    width: 100%;
    height: 100vh;
    margin: 0;
    background-image: url("../../../assets/images/gray-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.corner-image-bg {
    width: 100%;
    height: 100%;
    margin: 0;
    background-position: bottom right;
    background-image: url("../../../assets/images/happy-couple.png");
    background-repeat: no-repeat;
    background-size: contain;

    display: flex;
    align-items: center;
    justify-content: center;
}

.start-button {
    background-color: colors.$primary-yellow;
    font-family: typography.$font-bold;
    color: colors.$primary-green;
    padding: 20px 60px;
    border-radius: 25px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.start-button::after {
    content: " ";
    background-image: url("../../../assets/images/arrow.svg");
    background-size: 40px 20px;
    width: 40px;
    height: 20px;
    margin-left: 30px;
}

.loading.start-button::after {
    background-image: url("../../../assets/images/spinner-white.svg");
    background-position: center;
    background-size: 40px 40px;
    width: 40px;
    height: 20px;
}

.loading { 
    cursor: wait !important;
}