@use "../../../assets/scss/colors";
@use "../../../assets/scss/typography";

.action-button {
    font-family: typography.$font-bold;
    background-color: colors.$primary-yellow;
    color: colors.$primary-green;
    outline: none;
    padding: 8px 18px;
    border-radius: 8px;
    border: none;
    outline: none;
}

.action-button:hover {
    color: white;
}

.floating-panel {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px 20px;
    background-color: #FFFFFFD9;
    color: colors.$primary-green;
    box-shadow: -5px 0 30px #bbb;
    text-align: right;
}

.k4n-text-area {
    border: 2px solid colors.$primary-yellow;
    border-radius: 18px;
    padding: 20px;
    color: colors.$primary-green;
}

.json-obj {
    padding: 20px;
}