@use "../../../assets/scss/typography";

.ted-container {
  margin-top: 56px;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.panel-content {
  margin-top: 20px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 16px 24px;
}
.back-btn {
  float: left;
  border: transparent;
  background: transparent;
}
.ted-receipt-title {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #27646e;
  padding-bottom: 1rem;
}
.payment-receipt {
  padding-bottom: 1rem;
}
.ted-span-title-18 {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #737373;
  margin-right: 4px;
}
.ted-receipt-message {
  display: flex;
  height: 100px;
  align-items:initial;
  box-sizing: border-box;
}
.info-div {
  height: 55px;
  display: flex;
  flex-direction: column;
}
.info-span-bold-16 {
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #0a0203;
}
.info-span-grey-16 {
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #737373;
}
.ted-receipt-img-div {
  height: 50px;
  border-bottom: 1px solid #dadada;
  display: flex;
  align-items: center;
}
.ted-info-div {
  display: flex;
  height: 120px;
  gap: 70px;
  align-items: center;
  border-bottom: 1px solid #dadada;
}
.margin-top-30 {
  margin-top: 30px;
}
.receipt-transaction-id-div {
  display: flex;
  align-items: center;
  height: 120px;
  border-bottom: 1px solid #dadada;
}
.ted-receipt-capture {
  padding: 32px 52px;
}
