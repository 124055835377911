@use "../../../assets/scss/colors";
@use "../../../assets/scss/typography";

.main-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    // overflow: scroll;
}

.header-wrapper {
    height: 80px;
    width: 100%;
    background-color: colors.$primary-green;
    border-bottom: 1px solid #FFF;
}

.header {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: white;
}

.nav-link,
.nav-link:visited {
    text-decoration: none;
    color: white;
}

.nav-link:hover {
    text-decoration: none;
    color: colors.$primary-yellow;
    cursor: pointer;
    transition: color .1s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.nav-logo {
    margin: 20px 20px 20px 0;
    height: calc(100% - 40px);
}

.nav {
    width: 120px;
    display: flex;
    flex-direction: column;
    // padding-left: 10px;
    // padding-right: 10px;
    // gap: 20px;
    // margin-top: 20px;
    padding: 0;
    border-top: 1px solid #dee2e6;
}

.nav-item {
    // border-bottom: 1px solid #FFF;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fbb040;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    // transition: color 0.7s ease-in-out, background-color .7s ease-in-out, border-color .7s ease-in-out;
    // border-radius: 20px;
    gap: 10px;
    border-bottom: 1px solid #dee2e6;
}

.nav-item:hover {
    color: white;
    transition: all .1s ease-in-out;
    background-color: #fbb040;
}

.active {
    box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
    background-color: #fbb040;
    color: white;
}

a {
    text-decoration: none;
}

.text-green {
    color: colors.$primary-green
}

.flex-1 {
    flex: 1;
}

.content-wrapper {
    flex: 1;
    height: calc(100vh - 80px);
    overflow-y: scroll;
}

.nav-sub-menu {
    list-style: none;
    display: none;
    position: absolute;
    left: 120px;
    top: 79px;
    background: #ffffff;
    border: 1px solid #dee2e6;
    width: 280px;
    z-index: 1;
    padding: 0;
    height: calc(100% - 79px);
    flex-direction: column;
}

.nav-bar-item {
    width: 120px;
    height: 110px;
}

.sub-menu:hover .nav-sub-menu{
    display: flex !important;
}

.nav-bar {
    width: 100%;
    padding: 0;
    list-style: none;
}

.sub-align-left{
    align-items: flex-start !important;
    padding: 12px 24px !important;
}