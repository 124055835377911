@use "../../../assets/scss/colors";
@use "../../../assets/scss/typography";

.action-button {
    font-family: typography.$font-bold;
    background-color: colors.$primary-yellow;
    color: colors.$primary-green;
    outline: none;
    padding: 8px 18px;
    border-radius: 8px;
    border: none;
    outline: none;
}

.action-button:hover {
    color: white;
}

.floating-panel {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px 20px;
    background-color: #FFFFFFD9;
    color: colors.$primary-green;
    box-shadow: -5px 0 30px #bbb;
    text-align: right;
}

.k4n-text-area {
    border: 2px solid colors.$primary-yellow;
    border-radius: 18px;
    padding: 20px;
    color: colors.$primary-green;
}

.json-obj {
    padding: 20px;
}

.buttonRow{
    display: flex;
    justify-content: space-between;

}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid colors.$primary-green-new; /* Blue */
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}