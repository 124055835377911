@use "../../../assets/scss/colors";
@use "../../../assets/scss/typography";

.interactive-table-row:hover>* {
    cursor: pointer;
    color: colors.$primary-yellow  !important;
}

.search-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

}

// .search-input {
//     border: 1px solid colors.$primary-green;
//     border-radius: 12px 0 0 12px;
//     padding: 7px 18px;
//     outline: none;
//     caret-color: colors.$primary-yellow;
// }

.search-input {
    box-sizing: border-box;
    width: 381px;
    height: 60px;
    border: 2px solid colors.$primary-yellow;
    border-radius: 28px;
    font-family: "Satoshi-Regular";
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    padding-left: 46px;
    background-image: url(../../../assets//images/search_icon.svg);
    background-repeat: no-repeat;
    background-position: 90%;
}

.search-input::placeholder {
    color: colors.$primary-yellow;
    outline: none;
    border: none;
}

textarea:focus,
.search-input:focus {
    color: colors.$primary-yellow;
    border-radius: 28px;
    outline: none;
}

.search-input:focus::placeholder {
    color: transparent;
}


.search-button {
    background-color: colors.$primary-yellow;
    color: white;
    border: none;
    border-radius: 0 12px 12px 0;
    outline: none;
    padding: 0 15px;
}

.search-button:hover {
    color: colors.$primary-yellow;
}

.page-indicator {
    border-radius: 8px;
    color: colors.$primary-green;
    border: 1px solid colors.$primary-yellow;
    text-align: center;
    padding: 8px 13px;
    font-weight: bold;
}

.page-selector {
    font-weight: bold;
    border-radius: 8px;
    padding: 8px 13px;
    color: colors.$primary-green;
    border: 1px solid colors.$primary-yellow;
    background-color: transparent;
    outline: none;
    height: 100%;
    margin: 0 10px;
}

.filter-select {
    text-align: center;
    border: 1px solid colors.$primary-green;
    border-radius: 12px;
    outline: none;
    box-sizing: border-box;
    height: 60px;
    border: 2px solid colors.$primary-yellow;
    border-radius: 28px;
    font-family: "Satoshi-Regular";
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;
    padding-left: 25px;
    padding-right: 25px;
    background-repeat: no-repeat;
    background-position: 90%;
}

.card {
    border: none;
    box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
}